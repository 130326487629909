import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import ErrorBoundary from './views/ErrorBoundary';
import { QualioQFrontEndProvider } from '@qualio/ui-components';
import { CurrentUser } from '@qualio/ui-components/lib/types/CurrentUser';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from './lib/FeatureFlags/FeatureFlag.enum';
import { AsyncProviderConfig } from 'launchdarkly-react-client-sdk/src/types';
import { QueryClient, QueryClientProvider } from 'react-query';

const renderApp = async (
  Component: any,
  containerId: string,
  currentUser: CurrentUser,
  analytics?: SegmentAnalytics.AnalyticsJS,
  useFallBackOnSuspense?: boolean,
) => {
  (window as any).changeManagement = (window as any).changeManagement || {};

  const ldProviderConfig: AsyncProviderConfig = {
    clientSideID: process.env.REACT_APP_LAUNCHDARKLY_PUBLIC_KEY as string,
    user: {
      key: currentUser?.companyId + '',
      custom: {
        loggedInUserId: currentUser?.userId + '',
        createdTime: currentUser?.company?.createdTime + '',
        status: currentUser?.company?.status,
      },
    },
    options: {},
  };
  if (process.env.NODE_ENV === 'development') {
    ldProviderConfig.flags = {
      [FeatureFlags.REFERENCES_V2_TO_QRI_V2]: true,
      [FeatureFlags.QUALIO_FRONTEND_REFRESH]: true,
      [FeatureFlags.CHANGE_REQUEST_REFERENCE_DRAWER]: true,
    };
  }

  const LDProvider = await asyncWithLDProvider(ldProviderConfig);

  const client = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  return ReactDOM.render(
    <ErrorBoundary>
      <QueryClientProvider client={client}>
        <QualioQFrontEndProvider user={currentUser} analytics={analytics}>
          <LDProvider>
            <Suspense fallback={useFallBackOnSuspense ? <div>Loading...</div> : null}>{Component}</Suspense>
          </LDProvider>
        </QualioQFrontEndProvider>
      </QueryClientProvider>
    </ErrorBoundary>,
    document.getElementById(containerId),
  );
};

(window as any).renderChangeManagement = async (
  containerId: string,
  currentUser: CurrentUser,
  analytics?: SegmentAnalytics.AnalyticsJS,
) => {
  const Component = React.lazy(() => import(/* webpackChunkName: "app" */ './App'));

  renderApp(<Component />, containerId, currentUser, analytics, true);
};

(window as any).renderChangeManagementChangeRequestDrawer = async (containerId: string, currentUser: CurrentUser) => {
  const Component = React.lazy(
    () =>
      import(
        /* webpackChunkName: "sideDrawerChangeRequestView" */ './views/ChangeRequestSideDrawerView/ChangeRequestSideDrawerView'
      ),
  );

  renderApp(<Component />, containerId, currentUser);
};

(window as any).renderChangeManagementManageApproversModal = async (containerId: string, currentUser: CurrentUser) => {
  const Component = React.lazy(
    () => import(/* webpackChunkName: "manageApproversModal" */ './views/ManageApproversView/ManageApproversView'),
  );

  renderApp(<Component />, containerId, currentUser);
};

const unmountContainer = (containerId: string) => {
  try {
    if (document.getElementById(containerId) !== null) {
      ReactDOM.unmountComponentAtNode(document.getElementById(containerId)!);
    }
  } catch (e) {
    // even with the if statements its possible we have a race condition that is the if states the element exists BUT
    // then before we remove it the Application container has removed it
  }
};

(window as any).unmountChangeManagement = (containerId: string) => unmountContainer(containerId);

(window as any).unmountChangeManagementChangeRequestDrawer = (containerId: string) => unmountContainer(containerId);

(window as any).unmountChangeManagementManageApproversModal = (containerId: string) => unmountContainer(containerId);
